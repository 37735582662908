import React from "react"
import styled from "styled-components"
import { Box } from "../components/Core"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import PricingSection from "../sections/pricing/PricingSection"
import { device } from "../utils"

const BackDecorator = styled.div`
  position: absolute;
  box-sizing: border-box;
  @media ${device.xs} {
    width: 3.75rem;
    height: 3.75rem;
    border: 1px solid ${({ theme }) => theme.colors.dark};
    border-radius: 18.75rem;
    transform: translate(-1.6rem, 16rem);
  }
  @media ${device.sm} {
    transform: translate(-1.6rem, 15rem);
  }
  @media ${device.md} {
    width: 18.75rem;
    height: 18.75rem;
    border: 1px solid ${({ theme }) => theme.colors.linebreak};
    border-radius: 0px 18.75rem 18.75rem 0px;
    transform: translate(-0.4rem, 43rem);
  }
  @media ${device.lg} {
    transform: translate(0rem, 43rem);
  }
  @media ${device.xl} {
    transform: translate(0rem, 43rem);
  }
  @media (min-width: 1400px) {
    transform: translate(0rem, 43rem);
  }
  @media (min-width: 1600px) {
    transform: translate(0rem, 43rem);
  }
  @media (min-width: 1920px) {
    transform: translate(0rem, 43rem);
  }
`

const BackRightDecorator = styled.div`
  @media ${device.md} {
    position: absolute;
    width: 18.75rem;
    height: 18.75rem;
    right: 0px;

    border: 1px solid ${({ theme }) => theme.colors.linebreak};
    box-sizing: border-box;
    border-radius: 300px 0 0 300px;
    transform: translate(0rem, 77rem);
  }
`
const Fixer = styled(Box)`
  display: none;
  @media ${device.md} {
    display: block;
    position: fixed;
    z-index: 2;
    div {
      width: 179px;
      height: 44px;
      background: ${({ theme }) => theme.colors.light};
      border-radius: 40px;
      box-shadow: 0px 0px 40px rgba(0, 36, 50, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.25rem;
        text-align: center;
        letter-spacing: 0.02em;

        color: ${({ theme }) => theme.colors.dark};
      }
    }
  }
`
const StyledPageWrapper = styled.div`
  background: linear-gradient(
    180deg,
    #e7f8ff 0%,
    rgba(231, 248, 255, 0.05) 20%
  );

  @media ${device.md} {
    background: none;
  }
`

const PricingPage = () => {
  return (
    <>
      <StyledPageWrapper>
        <PageWrapper footerDark headerBlue>
          <Seo title="Precios" />
          <BackDecorator />
          <BackRightDecorator />
          <PricingSection isPage />
        </PageWrapper>
      </StyledPageWrapper>
    </>
  )
}
export default PricingPage
